<template>
  <main
    class="container pre-enrollment--content"
    :class="{ 'content--client-page': isClientPage }"
    >
    <router-view />
  </main>
</template>

<script>

export default {
  name: 'PreEnrollmentCustomRoutes',
  data () {
    return {
      isClientPage: this.$router.currentRoute.fullPath.includes('/custom') && !!this.$router.currentRoute.params.clientName
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.isClientPage = to.fullPath.includes('/custom') && !!to.params.clientName

    next()
  },
  mounted () {
    const routeGuard = this.$router.beforeEach((to, from, next) => {
      // add route guard validation here
      next(true)
    })

    this.$once('hook:destroyed', () => {
      routeGuard()
    })
  }
}
</script>

<style lang="scss">
.pre-enrollment--content {
  font-family: 'Centivo-Regular';

  &.content--client-page {
    margin: 0;
    max-width: 100% !important;
  }
}
</style>
